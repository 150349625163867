.dashboard-container {
  max-width: 1140px;
  margin-left: 320px;
  margin-right: auto;
  padding: 1rem;
}

.menu-bars {
  margin-left: 2rem;
  /* padding: 20px; */
  font-size: 2rem;
  background: none;
  /* background-color: green; */
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.cross .menu-bar-item {
  left: -200% !important;
}
.cross .menu-bar-item::after,
.cross .menu-bar-item::before {
  left: 200%;
}
.cross .menu-bar-item::after {
  transform: rotate(45deg);
  bottom: 0;
}
.cross .menu-bar-item::before {
  transform: rotate(-45deg);
  top: 0;
}
.menu-bars .menu-bar-item {
  width: 100%;
  height: 5px;
  background-color: #a7cc48;
  margin-bottom: 10px;
  position: absolute;
  top: 20px;
  transition: 0.3s;
}
.menu-bar-item::before {
  content: "";
  width: 100%;
  height: 5px;
  background-color: #a7cc48;
  display: block;
  position: absolute;
  top: 10px;
  transition: 0.3s;
}
/* 5A5A5C */
.menu-bar-item::after {
  content: "";
  width: 100%;
  height: 5px;
  background-color: #a7cc48;
  display: block;
  position: absolute;
  bottom: 10px;
  transition: 0.3s;
}

.navbarIConsAdjust {
  height: 30px;
}

.excite-sidebar-logo {
  /* height: 70px; */
  width: 100px;
  /* margin-left: 30px; */
}

.nav-menu {
  /* left: 0 !important; */
  transition: 350ms;
  background-color: rgb(255, 255, 255) !important;
  width: 300px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
}

.logo-bar {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 60px 5px;
}
/* on mobile hide side bar minimizer */
.logo-bar i {
  display: none;
  font-size: 36px;
  cursor: pointer;
  color: #a7cc48;
  transform: translateX(-20px);
  transition: 0.3s;
}

/*  */
.logo-bar img {
  width: 110px;
  height: 70px;
}
.sidebar-logo-hide {
  height: 0 !important;
}
.sidebar-log-out {
  background-color: #2c2d3f;
  position: absolute;
  bottom: 0;
}
.sidebar-log-out button {
  border: none;
  outline: none;
  width: 100%;
  background-color: transparent;
  color: white;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

/* .nav-menu.active {
    left: 0;
    transition: 350ms;
  } */

.nav-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: rgb(55, 190, 42) !important;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  font-weight: 600;
}

.nav-text a:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(55, 190, 42) !important;
}

.nav-menu-items {
  width: 100%;
  padding-inline-start: 5px !important;
  margin: 0rem;
  overflow: auto;
  padding-bottom: 40px;
}
/*  */
.nav-menu-items ::-webkit-scrollbar {
  width: 0.5rem;
}
.nav-menu-items ::-webkit-scrollbar-track {
  background-color: #2c2d3f;
}
.nav-menu-items::-webkit-scrollbar-thumb {
  background-color: #a7cc48;
}
/*  */
.menu-text-header {
  font-size: 1.5rem;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.navbar-toggle {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

/* span {
    margin-left: 16px;
  } */

.navBar-top-section {
  padding: 60px 5px;
  background-color: #2c2d3f;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 20px;
}

.navBar-top-section-left {
  /* width: 50%; */
  display: block;
  margin-left: auto;
  /* align-items: start; */
}

.navBar-top-section-right {
  /* width: 50%; */
  display: flex;
  /* flex-direction: column; */
  align-items: flex-end;
  justify-content: end;
  padding: 5px;
  color: #fff;
}

.navBar-top-section-right .navBar-top-text {
  font-size: 25px;
  padding-top: 20px;
  font-weight: 400;
  color: rgb(6, 155, 38);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.activer {
  left: -1000%;
  transition: 350ms;
  /* display: none; */
}

/* sidebar icons and text color rewrite */
.pro-icon{
  color: #a7cc48 !important;
}
.pro-item-content a{
  color:#fff !important
}
.pro-item-content:hover a{
  color: #a7cc48 !important;
}

.sidebar-link {
  list-style: none;
  cursor: pointer;
  transition: 0.3s;
}
.sidebar-link-active {
  background: #a7cc48 !important;
  color: white;
}

.sidebar-link-text {
  padding: 1rem 0 1rem 1rem;
  /* margin: 1rem; */
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.sidebar-link:hover {
  background: #a7cc60;
}
.sidebar-link-text:hover {
  /* padding: 1rem; */
  /* margin: 1rem; */
  /* font-size: 1.2rem; */
  color: #fff;
}
.nav-menu.activer {
  left: 0;
  transition: 350ms;
}
@media screen and (max-width: 769px) {
  .dashboard-container {
    margin-left: 0px;
  }
}

@media screen and (min-width: 789px) {
  .side-bar-section {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }
  .side-bar-section .view-area {
    overflow: auto;
  }
  .logo-bar i {
    display: inline;
  }
  .nav-menu {
    position: static;
    height: 100vh;
    /* height: fit-content; */
  }
  .nav-menu-items {
    padding-bottom: 0;
    flex: 1;
    overflow: auto;
  }
  .minimize-sidebar {
    width: 70px;
  }
  .view-area {
    flex: 1;
  }
  .sidebar-log-out {
    position: static;
  }
  .menu-bars {
    display: none;
  }
  .sidebar-children {
    overflow: auto;
  }
}
