/* New Footer */
.new-footer {
    background-color: #000000;
    padding: 100px 0;
  }
  .new-footer a{
      color: inherit;
  }
  .new-footer ul {
    list-style: none;
    padding: 0;
  }
  /* footer contact */
  .footer-contact h4::after {
    content: "";
    display: block;
    height: 2px;
    width: 50px;
    background-color: #a7cc48;
    margin-top: 10px;
  }
  .footer-contact li {
    color: rgba(250, 250, 250, 0.6);
    display: flex;
  }
  .footer-contact li i {
    margin-right: 15px;
    color: #a7cc48;
  }
  .footer-contact li a {
    display: flex;
    text-decoration: none;
  }
  
  .footer-about p {
    color: rgba(250, 250, 250, 0.6);
  }
  .new-footer-links ul li {
    margin-bottom: 10px;
  }
  .new-footer-links h4::after {
    content: "";
    display: block;
    height: 2px;
    width: 50px;
    background-color: #a7cc48;
    margin-top: 10px;
  }
  .new-footer-links ul li a {
    color: rgba(250, 250, 250, 0.6);
    text-decoration: none;
    transition: 0.3s;
  }
  .new-footer-links ul li a:hover {
    color: #a7cc48;
  }
  .mail-action-area {
    width: 100%;
  }
  .mailing h4::after {
    content: "";
    display: block;
    height: 2px;
    width: 50px;
    background-color: #a7cc48;
    margin-top: 10px;
  }
  .mailing p {
    color: rgba(250, 250, 250, 0.6);
  }
  .mail-action-area .mailing-input-wrapper {
    display: flex;
    height: 40px;
    margin-bottom: 30px;
  }
  .mail-action-area .mailing-input-wrapper i {
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2c2d3f;
    color: #a7cc48;
  }
  .mail-action-area .mailing-input-wrapper input {
    flex: 1;
    width: 100%;
    padding: 0 10px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff;
    border-bottom: 2px solid #a7cc48;
  }
  .mail-action-area button {
    background-color: #a7cc48;
    border: none;
    outline: none;
    padding: 5px 15px;
  }
  .icon-flex {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    padding-right: 1rem;
  }
  .icon-flex i {
    background-color: #a7cc4899;
    color: #2c2d3f;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 50%;
    transition: 0.3s;
  }
  .icon-flex i:hover {
    transform: scale(1.1);
  }
  
  .footer-border-stuff {
    border-top: 1px solid #777777;
  }
  
  .footer-border-stuff p {
    font-family: "Axiforma", "system-ui", "-apple-system", "BlinkMacSystemFont",
      "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "sans-serif",
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    color: #777777;
  }